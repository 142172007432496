// button_scroller_controller.js
import ApplicationController from "./application_controller";

export default class ButtonScrollerController extends ApplicationController {
  static targets = [
    'item',
    'scrollLimitReachedContainer' // div tag to show warning message
  ]; 

  /*** This method is for the case where the element has
   * a data-case attribute with the value "linked-column-modal" used in _continuing_columns_modal */  
  initializeLinkedColumnModal(){
    const modalId = this.element.dataset.relatedModalId;
    let modal = document.getElementById(modalId);

    modal.instance = this;
    modal.addEventListener('shown.bs.modal', function() {
      this.instance.itemTargets[0]?.scrollIntoView({ behavior: 'instant', block: 'center' });
    })
  }

  initialize(){
    window['button_scroller'] = this; // to use showNextItem in cropper to implement canvas cahange page down/ page up key functionality
    // Determine the case based on the data-case attribute of the element
    this.case = this.element.dataset.case;
    // Check if the case corresponds to the _continuing_columns_modal
    if (this.isLinkedColumnModal()) {
      this.initializeLinkedColumnModal();
    }
  }

  // Check if the case corresponds to the _continuing_columns_modal
  isLinkedColumnModal(){
    return this.case === 'linked-column-modal'
  }

  hotkeys(eventObject) {
    if (preview_modal){
      return true
    }
    const pgUp   = 33
    const pgDown = 34
    const up     = 38
    const down   = 40
    
    if(this.isLinkedColumnModal()){
      if(eventObject.keyCode == pgDown) {
        eventObject.preventDefault();
        this.showNextItem();
      }
      else if(eventObject.keyCode == pgUp) {
        eventObject.preventDefault();
        this.showPreviousItem();
      }
    } else { // else case is for linked slices scroll
      // TODO: Improve. Dont direct use cropper or make some proper communication mechanism
      if (cropper.inRectangleEditMode() || cropper.lShapeMode)
        return true;

      if(eventObject.keyCode == down) {
        eventObject.preventDefault();
        this.showNextItem(eventObject);
      }
      else if(eventObject.keyCode == up) {
        eventObject.preventDefault();
        this.showPreviousItem(eventObject);
      } else {
        return true; // returning true to handle bubble up affect // https://www.javatpoint.com/event-bubbling-and-capturing-in-javascript
      }
    }
  }

  onScroll(){
    if (this.isLinkedColumnModal()) // Check if the case corresponds to the _continuing_columns_modal
      return true;

    if (!cropper)
      return true;

    // TODO: Use current canvas Index method from cropper_controller
    let currentCanvasIndex = 0;
    // Find the index of the first canvas element currently in the viewport using cropper.isElementInViewport
    let itemIndex = $('.js-canvas-container').toArray().findIndex(item => cropper.isElementInViewport(item))
    // Get the total number of canvas
    let canvasContainersLength = $('.js-canvas-container').length

    if(itemIndex >= 0){ // if element found in the view port
      currentCanvasIndex = itemIndex; // Set the currentCanvasIndex to the found index
    } else { // if not found, find the previous nearest
      currentCanvasIndex = $('.js-canvas-container').toArray().findIndex(item => item.getBoundingClientRect().y >= 0);
    }

    if (currentCanvasIndex == 0){
      $('.js-prev-canvas-btn').css('background-color', '#bfbfbf'); // if current canvas on screen is first canvas then turn 'previous button' grey 
    }
    else {
      $('.js-prev-canvas-btn').css('background-color', '#004080'); // otherwise button color stays blue
    }
    if (currentCanvasIndex == canvasContainersLength - 1) {
      $('.js-next-canvas-btn').css('background-color', '#bfbfbf'); // if current canvas on screen is last canvas then turn 'next button' grey
    } 
    else {
      $('.js-next-canvas-btn').css('background-color', '#004080'); // otherwise button color stays blue
    }       
  }

  showNextItem(e){
    // Filtering items that are currently visible in the viewport
    let visibleItems = this.itemTargets.filter(item => this.isElementInViewport(item));
    // Setting the item index to the last visible item in the viewport
    let itemIndex = this.itemTargets.indexOf(visibleItems[visibleItems.length - 1]);
    
    let nextItem = null;
    if(itemIndex >= 0){ // if element found in the view port
      if (itemIndex != this.itemTargets.length - 1)
        nextItem = this.itemTargets[itemIndex + 1]
    } else { // if not found, find the previous nearest
      let nextItems = this.itemTargets.filter(item => item.getBoundingClientRect().y >= 0);
      if (nextItems.length > 0) {
        nextItem = nextItems.reduce(function(prevItem, currItem) {
          return (prevItem.getBoundingClientRect().y < currItem.getBoundingClientRect().y) ? prevItem : currItem
        }, nextItems[0])
      }
    }

    if (this.isLinkedColumnModal() && !nextItem){
      this.showScrollLimitReachedWarning('bottom'); // used only in _continuing_columns_modal case
    } else {
      // As hotkeys are used in multiple controllers, if next item is found for highlight, we stop propagation to cropper. Otherwise, we send call to cropper to handle the case
      if (nextItem){
        nextItem.scrollIntoView({ behavior: 'instant', block: 'center' });
        e.stopImmediatePropagation()
      } else {
        // Call is then handled by cropper
      }
    }
  }

  showPreviousItem(e){
    let itemIndex = this.itemTargets.findIndex(item => this.isElementInViewport(item))

    let previousItem = null;
    if(itemIndex >= 0){ // if element found in the view port
      if (itemIndex != 0)
        previousItem = this.itemTargets[itemIndex - 1]
    } else { // if not found, find the previous nearest
      let previousItems = this.itemTargets.filter(item => item.getBoundingClientRect().y <= 0);
      if (previousItems.length > 0) {
        previousItem = previousItems.reduce(function(prevItem, currItem) {
          return (prevItem.getBoundingClientRect().y > currItem.getBoundingClientRect().y) ? prevItem : currItem
        }, previousItems[0])
      }
    }

    if (this.isLinkedColumnModal() && !previousItem){
      this.showScrollLimitReachedWarning('top'); // used only in _continuing_columns_modal case
    } else {
      if (previousItem){
        previousItem.scrollIntoView({ behavior: 'instant', block: 'center' });
        e.stopImmediatePropagation()
      } else {
        // Call is then handled by cropper
      }
    }
  }

  // https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport
  isElementInViewport (el) {
    let rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (this.element.clientHeight - 30 ) && /* so el is atleast 30px above the bottom of modal window*/
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }

  // used only in _continuing_columns_modal case
  showScrollLimitReachedWarning(reached = 'top') {
    let warningMessage = ''
    if (reached == 'top')
      warningMessage = 'You are already on top'
    else
      warningMessage = 'You are already on bottom'
    this.scrollLimitReachedContainerTarget.innerHTML = warningMessage

    this.scrollLimitReachedContainerTarget.classList.toggle("d-none", false); // style.display = 'block';
    setTimeout(() => {
      this.scrollLimitReachedContainerTarget.classList.toggle("d-none", true); // style.display = 'none';
    }, 1500)
  }
}
