// canvas_controller.js
import ApplicationController from "./application_controller";
import { initUtils } from '../lib/canvas_utils'
import BuildlqColors from '../lib/cropper/buildlq_colors'
import { CanvasScrollbar } from "../lib/canvas_scrollbar";
import Zoom from '../lib/zoom'
import API from '../lib/api'

import { initSlicesManagement } from '../lib/cropper/slices_management'

const clipperLib     = require('clipper-lib');
const greinerHormann = require('greiner-hormann')

const BLUR_SIZE = 0

export default class CanvasController extends ApplicationController {
  static targets = [
    'mainCanvas',
    'hoverCanvas',
    'container',
    'xScroll',
    'yScroll',
  ];

  // Gets called in initialize()
  #assignDefaultValues(){
    this.isMouseDown = false;
    this.rectangleSlicingInPorgress = false
    this.rectangleStartPoint = { x: 0, y: 0 }
    this.moveOnInterval = {}
    this.mainContext = {}
    this.hoverContext = {}
    this.originalImagePath = ''
    this.zoomInstance = {}
    this.mainImage = new Image()
    this.parentImage = new Image()
    this.lastMouseDownPoint = { x: null, y: null }
    // if point is clicked/marked outside the main image (on split,slice,highlight stage) then this flag 'pointMarkedOutsideMainImage' is set true so that area outside image can be excluded from drawn path using intersection functionality in 'clipit()' method // feature/1202-while-drawing-clicking-outside-of-image-should-be-possible-split-highlight
    this.pointMarkedOutsideMainImage = false
    this.polygonalSlicingStartedFromLeft = false
    this.polygonalSlicingStartedFromRight = false
  }

  initialize() {
    // Global variables for canvases and its instances
    window['canvasInstances'].push(this);
    this.#assignDefaultValues();
    this.pageSetId         = this.element.dataset.pageSetId;
    this.pageId            = this.element.dataset.pageId;
    this.regionId          = this.element.dataset.regionId;
    this.columnId          = this.element.dataset.columnId;
    this.originalImagePath = this.element.dataset.originalImage;
    this.mainContext       = this.mainCanvasTarget.getContext('2d')
    this.hoverContext      = this.hoverCanvasTarget.getContext('2d')
    initUtils(this.mainContext)
    initUtils(this.hoverContext)

    this.mainContext.parentPolygons  = JSON.parse(this.element.dataset.parentPolygons)
    this.mainContext.parentPolygon   = this.mainContext.parentPolygons[this.mainContext.parentPolygons.length - 1] || []
    this.mainContext.rotations       = (JSON.parse(this.element.dataset.rotations) || []).map((r) => parseFloat(r))
    this.mainContext.currentRotation = this.mainContext.rotations[this.mainContext.rotations.length - 1] || 0

    this.mainImage.crossOrigin = 'anonymous'
    this.mainImage.onload = () => this.imageOnLoad()

    // TODO: What is happening here in this 'if' clause
    if (this.mainContext.parentPolygons.length) {
      this.mainContext.blurSize = BLUR_SIZE
      this.parentImage.crossOrigin = 'anonymous'

      const parentRotation = this.mainContext.rotations[0]
      let rotationLoaded = !parentRotation
      this.parentImage.onload = () => {
        if (!rotationLoaded) {
          const tempCanvas = document.createElement('canvas')
          const tempContext = tempCanvas.getContext('2d')
          initUtils(tempContext)
          tempContext.image = this.parentImage
          tempContext.currentRotation = parentRotation
          const { width, height } = tempContext.getRotatedDimensions(this.parentImage, tempContext.currentRotation)
          const imageRect = [{ x: 0, y: 0 }, { x: width, y: 0 }, { x: width, y: height }, { x: 0, y: height }]
          this.parentImage.src = tempContext.getClippedDataUrl(imageRect, this.parentImage, parentRotation)
          tempCanvas.remove()
          rotationLoaded = true
          return
        }

        this.mainContext.parentImage = this.parentImage
        this.mainImage.src = this.mainContext.contextualRect()
      }
      this.parentImage.src = this.originalImagePath
    } else {
      this.mainImage.src = this.originalImagePath
    }
    this.#initializeZoom();
  }

  #initializeZoom(){
    this.zoomInstance = Zoom().init({
      canvasContext: this.mainContext,
      hoverCanvas: this.hoverCanvasTarget,
      redraw: (mousePoint = null) => {
        this.redraw()
        this.outline()
        this.drawHoverCanvas(mousePoint);
      },
      updateScrollbars: (...args) => this.canvasScrollbar.updateScrollbars(...args)
    })
    this.canvasScrollbar = new CanvasScrollbar(this.mainContext, this.xScrollTarget, this.yScrollTarget, this.zoomInstance);
    // Zoom Initialize End, Initial zoom settings are added in imageOnLoad method
  }

  async imageOnLoad() {
    this.mainContext.image                  = this.mainImage
    let canvasImageDimensions               = this.mainContext.fitImageInCanvas(this.mainImage, this.containerTarget.parentNode)
    this.mainContext.canvasHorizontalMargin = canvasImageDimensions.canvasHorizontalMargin;
    this.mainContext.canvasVerticalMargin   = canvasImageDimensions.canvasVerticalMargin;
    // Setting canvas background height
    // this.hoverContext.canvas.parentElement.style.minHeight = '75vh'; // similar to h drawing area // https://trello.com/c/fiSBNbCY/485-the-slice-in-the-canvas-is-too-tight-dev-fix-canvas-size-for-small-columns
    // this.hoverContext.canvas.parentElement.style.height = this.mainContext.canvas.height + 'px';

    this.mainContext.blurOffset = this.mainContext.blurSize / this.mainContext.imageRatio()
    this.zoomInstance.initZoom()

    // TODO: This should be in initiliazer if possible
    const rawClips = JSON.parse(this.element.dataset.clips)
    const newClips = []

    switch (cropper.stage) {
      case 'split':
        this.zoomFitWidthIfRequired();
        for (let clip of rawClips) {
          newClips.push({
            id: clip.id,
            polygon: this.mainContext.unscaledPath(clip.polygon),
            scaledPolygon: clip.scaledPolygon,
            data: this.mainContext.getClippedDataUrl(clip.polygon, this.mainContext.image),
            entryType: clip.entryType,
            number: clip.number,
          });
        }
        this.mainContext.clips = newClips;
        break
      case 'slice':
        this.zoomWidth({ horzintalZoomValue: 0.6, initialY: 0 })
        await this.mainContext.prepareSlicesFromRawClips(rawClips);
        break;
      case 'highlight':
        this.zoomFitWidth({ initialY: 0 });
        this.zoomInstance.moveVertically();
        for (let clip of rawClips) {
          // "CLIP_CREATION" from backend passed values
          // markedForLinkingDuplicate
          newClips.push({
            id: clip.id,
            polygon: this.mainContext.unscaledPath(clip.polygon),
            scaledPolygon: clip.scaledPolygon,
            data: this.mainContext.getClippedDataUrl(clip.polygon, this.mainContext.parentImage),
            entryType: clip.entryType,
            number: clip.number,
            subNumber: clip.subNumber,
            inParts: !!clip.subNumber,
            regionId: clip.regionId,
            otherWordCategory: clip.otherWordCategoryId,
          });
        }
        this.mainContext.clips = newClips;

        break
      default:
    }

    cropper.pushState(true, cropper.unsavedChanges);
    cropper.getClips() // To append canvasIndex and other attributes to clip by getClips emthod
    this.redraw()
    this.zoomInstance.runUpdateScrollbars(); // To display scrollbars at correct position initially
  }

  preventContextMenu(e) {
    e.preventDefault()
  }

  handleMousedown(e) {
    e.preventDefault()
    e.stopPropagation()
    this.isMouseDown = true;
    if(this.grabbingScrollEnabled()) {
      this.setHoverCursor('grabbing');
      return;
    }
    this.lastMouseDownPoint = this.mainContext.mousePoint(e)
    switch (e.which) {
      case 1:
        this.applyClick(e)
        break
      case 3:
        this.applyRightClick(e)
        break
    }
  }

  handleDblClick(e) {
    e.preventDefault()
    e.stopPropagation()

    if (cropper.slicingMode !== 'polygon')
      return
    if (cropper.stage !== 'split' && cropper.stage !== 'highlight')
      return
    if (this.mainContext.currentPath.length <= 3)
      return

    const lastPoint = this.mainContext.transformToOriginalCoordinates(this.mainContext.mousePoint(e))
    
    // excluding split stage from early return to allow double click outside page image
    if (!this.mainContext.validatePoint(lastPoint, cropper.stage) && cropper.stage !== 'split')
      return

    if (cropper.selectionMode == 'add' || cropper.selectionMode == 'remove'){
      [cropper.polygonInEditMode, cropper.polygonIndexInEditMode] = this.findPolygonForAppendOrDiff(e);
    }

    this.clipIt()
    this.redraw()
  }

  setHoverCursor(cursorStyle) {
    this.hoverCanvasTarget.style.cursor = cursorStyle;
  }

  grabbingScrollEnabled() {
    return cropper.grabScroll;
  }

  handleHover(e = null, currentHoverPoint = null) {
    if(this.grabbingScrollEnabled()) {
      this.#handleGradScrollHover(e)

      currentHoverPoint = this.hoverContext.mousePoint(e)
      this.drawHoverCanvas(currentHoverPoint, e?.shiftKey);
    } else {
      this.setHoverCursor('default'); // Resetting the hover cursor
      if (e && !currentHoverPoint)
        currentHoverPoint = this.hoverContext.mousePoint(e)
      this.drawHoverCanvas(currentHoverPoint, e?.shiftKey);
    }
  }

  #handleGradScrollHover(e){
    this.setHoverCursor('grab');
    if(this.isMouseDown) {
      e.preventDefault();

      this.setHoverCursor('grabbing');
      this.canvasScrollbar.handleMove(e);
    }
  }

  drawHoverCanvas(currentHoverPoint, shiftKey = false) {
    switch (cropper.slicingMode) {
      case 'polygon':
        if (this.mainContext.currentPath.length > 0)
          this.drawDirection(
              currentHoverPoint,
              shiftKey && this.mainContext.lockDirection(this.mainContext.transformToOriginalCoordinates(currentHoverPoint), this.mainContext.currentPathLastPoint())
          )
        break
      case 'rectangle':
        this.hoverContext.clear()
        if (this.rectangleSlicingInPorgress)
          this.drawRectangle(currentHoverPoint)
        if (cropper.lShapeMode) {
          this.drawLShapedMode();
        }
        this.drawCross(currentHoverPoint)
        break
      case 'line':
        this.drawStraightLine(currentHoverPoint)
        break
      default:
        break
    }
  }

  handleZoom(e) {
    if (e.altKey || e.ctrlKey) {
      e.preventDefault();
      
      const delta = e.wheelDelta ? e.wheelDelta / 200 : e.detail ? -e.detail : 0
      if (delta)
        this.zoomInstance.zoom(delta, e.altKey)
      return false
    } else {
      e.preventDefault();
      this.canvasScrollbar.handleMove(e);
    }
  }

  zoomFitWindow() {
    const ratio = Math.min(this.mainContext.canvas.height / this.mainImage.height, this.mainContext.canvas.width / this.mainImage.width);
    this.zoomInstance.zoomFactor(Math.max(1, ratio * this.mainContext.imageRatio()), true, true)
  }

  // horzintalZoomValue is the amount of widht, 0.5 means 50% widht of the canvas
  // InitialY sets the vertical starting point of image
  zoomWidth({ horzintalZoomValue = 0.5, initialY = undefined } = {}) {
    this.zoomInstance.zoomFactor(Math.max(1, this.mainContext.canvas.width / this.mainImage.width * (this.mainContext.imageRatio() * horzintalZoomValue)), true, false, undefined, initialY);
  }

  // Why is this method in canvas_controller, and not in zoom file
  zoomFitWidth({ zoomFactor = undefined, initialY = undefined } = {}) {
    // Added this factor to avoid scroll-bar above the image # https://trello.com/c/ueZJ6eMc/238-cannot-highlight-from-top-right-corner
    let fitWidthFactor = 0.97;
    this.zoomFitHeight();
    if(zoomFactor == undefined){
      zoomFactor = this.mainContext.canvas.width / this.mainImage.width * (this.mainContext.imageRatio() * fitWidthFactor)
    }
    this.zoomInstance.zoomFactor(zoomFactor, true, false, undefined, initialY);
  }

  // TODO: What is this x
  zoomFitHeight(x) {
    this.zoomInstance.zoomFactor(
      Math.max(1, this.mainContext.canvas.height / this.mainImage.height * this.mainContext.imageRatio()),
      false, true,
      x
    );
  }

  zoomFitWidthIfRequired(){
    let height = this.mainImage.height;
    let width  = this.mainImage.width;

    // If width is greater than height, then zoom out image for better view
    if(width > height){
      this.zoomFitWidth()
    } else {
      // Default zoom
      this.zoomInstance.zoom(-0.15);
    }
  }

  startMove(e) {
    this.hideHoverCanvas()

    if (this.mainContext.currentPath.length <= 0)
      return
    if (this.mainContext.globalZoomFactor <= 1)
      return

    const tempX = e.offsetX || (e.pageX - this.hoverCanvasTarget.offsetLeft)
    const tempY = e.offsetY || (e.pageY - this.hoverCanvasTarget.offsetTop)
    const movingLeft = tempX < 0
    const movingUp = tempY < 0
    const movingRight = tempX > this.mainCanvasTarget.width
    const movingDown = tempY > this.mainCanvasTarget.height
    this.moveOnInterval = setInterval(() => {
      const moveResult = this.zoomInstance.move({ movingLeft, movingUp, movingRight, movingDown })
      if (!moveResult)
        clearInterval(this.moveOnInterval)
    }, 5 * Math.sqrt(this.mainContext.globalZoomFactor))
  }

  stopMove(e) {
    this.showHoverCanvas()

    clearInterval(this.moveOnInterval)
  }

  rotate(e) {
    const sign = e.currentTarget.dataset.value === 'left' ? -1 : 1
    const rotationStep = 0.3
    this.mainContext.currentRotation += sign * rotationStep
    this.mainContext.gridMode = true

    let canvasImageDimensions               = this.mainContext.fitImageInCanvas(this.mainImage, this.containerTarget.parentNode)
    this.mainContext.canvasHorizontalMargin = canvasImageDimensions.canvasHorizontalMargin;
    this.mainContext.canvasVerticalMargin   = canvasImageDimensions.canvasVerticalMargin;
    this.mainContext.blurOffset = this.mainContext.blurSize / this.mainContext.imageRatio()

    this.redraw()
  } 

  resetPolygonalSlicingFlags() {
    this.polygonalSlicingStartedFromLeft  = false
    this.polygonalSlicingStartedFromRight = false
  }

  //Functions//
  // Mouse Down - Left Click Case
  applyClick(e) {
    if (this.grabbingScrollEnabled()){
      return;
    }
    if (cropper.lShapeMode) {
      cropper.resetLShapedMode();
      return;
    }
    const lastPoint = this.mainContext.transformToOriginalCoordinates(this.mainContext.mousePoint(e));
    if (e.ctrlKey) {
      if (this.resetPath()) {
        return;
      }
      if (cropper.slicingMode === 'line') {
        return;
      }
      this.redraw();
      return;
    }

    // To stop making polygon when we change entry type
    if (e.altKey && e.shiftKey) {
      return;
    }

    const currentPathLastPoint = this.mainContext.currentPathLastPoint()
    if (this.mainContext.currentPath.length > 0) {
      this.mainContext.lockLine(lastPoint, currentPathLastPoint, e.shiftKey && this.mainContext.lockDirection(lastPoint, currentPathLastPoint))
    }
    
    // if current stage is split stage and point is clicked/marked outside main image then setting flag 'pointMarkedOutsideMainImage' to true so that area outside image can be excluded from drawn path using intersection functionality in 'clipit()' method 
    // validation of point ouside the page image is required only for adding tools to restrict them from drawing outside valid area, as they can draw column outside valid area, 
    if(cropper.isSplitStage() && (cropper.selectionMode === 'add' || cropper.selectionMode === 'none') && !this.mainContext.validatePoint(lastPoint, cropper.stage)) {
      this.pointMarkedOutsideMainImage = true;
    }

    // if current stage is slice stage and point is clicked/marked outside main image and if slicing mode is polygon then we only validate a point on the left side of image through validatePoint, if clicked on the right side of image then we dont allow the click and return
    // in slice stage this functionality is for polygon
    if(cropper.isSliceStage() && cropper.slicingMode !== 'line' && !this.mainContext.validatePoint(lastPoint, cropper.stage, true)){
      return;
    }

    // if current stage is highlight stage and point is clicked/marked/drawn outside main image then setting flag 'pointMarkedOutsideMainImage' to true so that area outside image can be excluded from drawn path using intersection functionality in 'clipit()' method 
    if(cropper.isHighlightStage() && (cropper.selectionMode === 'add' || cropper.selectionMode === 'none') && !this.mainContext.validatePoint(lastPoint, cropper.stage)){
      this.pointMarkedOutsideMainImage = true;
    }

    if (false && cropper.slicingMode !== 'line' && cropper.selectionMode !== 'none' && !cropper.polygonInEditMode && !this.mainContext.currentPath.length) {
      [cropper.polygonInEditMode, cropper.polygonIndexInEditMode] = this.mainContext.findPolygonByPointInside(lastPoint);
    }

    cropper.resetUndo()

    switch (cropper.slicingMode) {
      case 'polygon':
        const lockedToStart = this.mainContext.lockToStart(lastPoint, false)
        if (!lockedToStart){
          if(this.mainContext.currentPath.length == 0){
            // When user starts drawing an entry
            if (e.shiftKey){
              cropper.setDiffTemporarySlicingMode()
            } else if (e.altKey && !e.shiftKey) { // TODO: Make helper to check action based on keys pressed
              cropper.setAppendTemporarySlicingMode()
            }
          }

          this.mainContext.currentPath.push(lastPoint)
          cropper.pushState(false, true)
        }
        // TODO: Please explain this condition
        // This works in case Polygon tool is selected, and a new polygon is created with it. Additionally, if we are Append/Diff(+/-), we need to a select a Polygon. That polygon is being selected here.
        if (((cropper.selectionMode !== 'none' && !cropper.polygonInEditMode) || cropper.isAnyTemporarySlicingMode()) && lockedToStart) {
          [cropper.polygonInEditMode, cropper.polygonIndexInEditMode] = this.findPolygonForAppendOrDiff(e);
        }
        this.outline()

        switch (cropper.stage) {
          case 'highlight':
          case 'split':
            if (lockedToStart) {
              this.clipIt()
              this.redraw()
            }
            break
          case 'slice':
            if (this.mainContext.currentPath.length > 1 &&
                !this.mainContext.isInsidePolygon(this.mainContext.scaledPoint(lastPoint), this.mainContext.parentPolygon)) {
              this.mainContext.lockSlicingPath()
              this.clipIt(false)
              this.resetPolygonalSlicingFlags()
              this.redraw()
            }
            break
          default:
            throw new Error('unknown stage')
        }
        break
      case 'rectangle':
        // When user starts drawing an entry
        if (e.shiftKey){
          cropper.setDiffTemporarySlicingMode()
        } else if (e.altKey && !e.shiftKey) { // TODO: Make helper to check action based on keys pressed
          cropper.setAppendTemporarySlicingMode()
        } 
        
        this.rectangleSlicingInPorgress = true
        this.canvasScrollbar.hideScrollbars()
        this.rectangleStartPoint = lastPoint
        break
      case 'line':
        this.mainContext.currentPath.push({
          x: 0,
          y: lastPoint.y
        })
        this.mainContext.currentPath.push({
          x: this.mainCanvasTarget.width,
          y: lastPoint.y
        })
        this.mainContext.lockSlicingPath()
        this.clipIt(false)
        this.redraw()
        break
      default:
        throw new Error('unknown slicing mode')
    }
  }

  handleMouseUp(e) {
    e.preventDefault()
    e.stopPropagation()
    this.isMouseDown = false;

    if (e.which != 1){ // Left click
      return true;
    }

    if(this.grabbingScrollEnabled()){
      this.setHoverCursor('grab');
      return;
    }

    if (!(e.ctrlKey && e.altKey) && this.rectangleSlicingInPorgress) { // Observation: false in slice stage
      // Clip Creation/Updation Case
      const lastPoint = this.mainContext.fitPoint(this.mainContext.transformToOriginalCoordinates(this.mainContext.mousePoint(e)));

      this.hoverContext.clear()
      this.mainContext.drawRectangle(lastPoint, this.rectangleStartPoint)
      // This is checking whether we are in the Polygon extend or minimize feature, and hence, we are saving the selected polygon in the instance variable
      // Make helper method for this approach, cropper.selectionMode !== 'none'
      // TODO: Not a good way to handle things like that, set variables where required instead of setting global variables
      if ((cropper.selectionMode !== 'none' && !cropper.polygonInEditMode) || cropper.isAnyTemporarySlicingMode())
        [cropper.polygonInEditMode, cropper.polygonIndexInEditMode] = this.findPolygonForAppendOrDiff(e)
      this.clipIt(false)
      this.hoverContext.clear()
      this.mainContext.clearCurrentPath()
      this.rectangleSlicingInPorgress = false
      this.canvasScrollbar.showScrollbars()
      cropper.redraw();
    } else if (e.ctrlKey && !cropper.lShapeMode) {
      if (cropper.isSplitStage()){
        let currentHoverPoint = this.mainContext.fitPoint(this.mainContext.transformToOriginalCoordinates(this.mainContext.mousePoint(e)));
        if(!this.mainContext.isHoverPointInCanvas(currentHoverPoint)){
          // If clicked outside image, do nothing
          return;
        }

        var selectedClip = this.mainContext.findClickedClip(currentHoverPoint);
        if(selectedClip){
          selectedClip.markedForLinking = !selectedClip.markedForLinking;
          let markedClips = cropper.getClips().filter((clip) => clip.markedForLinking && clip != selectedClip);
          markedClips.forEach((clip) => {
            clip.markedForLinking = false;
          })
        } else {
          // Un select all clips
          let markedClips = cropper.getClips().filter((clip) => clip.markedForLinking);
          markedClips.forEach((clip) => {
            clip.markedForLinking = false;
          })
        }
        cropper.redraw();
      } else if (cropper.isHighlightStage()) {
        let currentHoverPoint = this.mainContext.fitPoint(this.mainContext.transformToOriginalCoordinates(this.mainContext.mousePoint(e)));
        if(this.mainContext.isHoverPointInCanvas(currentHoverPoint)){
          var selectedClip = this.mainContext.findClickedClip(currentHoverPoint);
          // Clip selected is present
          if(selectedClip){
            // If selected is already in_parts, It means we need to revert them, and make individual clips
            // Separate clip
            // Mark clip for linking
            // Unmark clip for linking
            // If shift key pressed, then we need to duplicate that clip even though its already in parts
            if(!e.shiftKey && selectedClip.inParts){
              cropper.separateClips(selectedClip);
              cropper.setUnsavedChanges();
            } else if (selectedClip.markedForLinking){
              selectedClip.markedForLinking = false
              selectedClip.markedForLinkingDuplicate = false
            } else {
              let markedClips = cropper.getClips().filter((clip) => clip.markedForLinking);
              markedClips = markedClips.sort((a,b) => a.number - b.number)
              // find highest and lowest number from markedClips
              let highestClipNumber
              let lowestClipNumber
              if (markedClips.length >= 1) {
                highestClipNumber = markedClips[markedClips.length - 1].number
                lowestClipNumber = markedClips[0].number
              }
              if ((markedClips.length >= 26)){
                //do nothing
              } else {
                // If shift key is also pressed, it means we need to create a new clip
                if(e.shiftKey){
                  selectedClip.markedForLinking = true;
                  selectedClip.markedForLinkingDuplicate = true
                } else {
                  selectedClip.markedForLinking = true;
                }
              }
            }
          } else {
            let markedClips = cropper.getClips().filter((clip) => clip.markedForLinking);
            markedClips.forEach((clip) => {
              clip.markedForLinking = false;
            })
          }
          cropper.redraw();
        }
      }
    } else if (e.altKey && e.shiftKey && !cropper.lShapeMode) {
      // altkey + shiftkey + mouseUp(leftButton) event used to change entry type
      var currentHoverPoint = this.mainContext.transformToOriginalCoordinates(this.mainContext.mousePoint(e));
      if(this.mainContext.isHoverPointInCanvas(currentHoverPoint)){
        var selectedClip = this.mainContext.findClickedClip(currentHoverPoint); 

        // Clip selected is present
        if((selectedClip && selectedClip.entryType != cropper.entryType) || (selectedClip && cropper.entryType == 'other' && selectedClip.otherWordCategory != cropper.currentOtherWordCategory)){
          cropper.changeClipType(selectedClip)          
          cropper.setUnsavedChanges()
        } 
      }
      cropper.redraw();
    }
  }

  findPolygonForAppendOrDiff(e){
    let polygonAndIndex = [false, false]
    let markedClips            = this.mainContext.clips.filter((clip) => clip.markedForLinking)
    let lastMouseDownPoint     = this.mainContext.transformToOriginalCoordinates(this.lastMouseDownPoint);
    let clipDrawingStartedFrom = this.mainContext.findClickedClip(lastMouseDownPoint)
    // Precedence 1 to selected Clip
    if (markedClips.length > 0) {
      polygonAndIndex = this.mainContext.findPolygonByLargestIntersection({ filteredClips: markedClips })

      if (polygonAndIndex[0]){
        return polygonAndIndex
      }
    }

    if (cropper.isRectangleToolMode()){
      if (this.mainContext.findIntersectingPolygons().length > 1){
        // do nothing
      } else {
        polygonAndIndex = this.mainContext.findPolygonByLargestIntersection();
      }
    } else if (cropper.isPolygonToolMode()){
      if (this.mainContext.findIntersectingPolygons().length > 1){
        let found = false
        this.mainContext.currentPath.forEach((point) => {
          if (!found){
            polygonAndIndex = this.mainContext.findPolygonByPointInside(point)
            if (polygonAndIndex[0]){
              found = true;
            }
          } else {
            // Do nothing
          }
        })
      } else {
        polygonAndIndex = this.mainContext.findPolygonByLargestIntersection();
      }
    }

    return polygonAndIndex;
  }

  applyRightClick(e) {
    const currentPoint = this.mainContext.transformToOriginalCoordinates(this.mainContext.mousePoint(e))

    if (this.resetPath())
      return
    if (this.resetRectangle())
      return
    let removeResult = false
    switch (cropper.stage) {
      case 'highlight':
      case 'split':
        if (e.ctrlKey) {
          if (e.altKey) {
            // Selecting element to change its shape with LShaped tool
            [cropper.polygonInEditMode, cropper.polygonIndexInEditMode] = this.mainContext.findPolygonByPointInside(currentPoint)
            if (cropper.polygonInEditMode) {
              cropper.displayLShapedMode = 'corner'
              this.drawLShapedMode(currentPoint)
              cropper.pushState(false, cropper.unsavedChanges)
              cropper.lShapeMode = true
              this.redraw()
            }
          } else {
            let currentHoverPoint = this.mainContext.fitPoint(this.mainContext.transformToOriginalCoordinates(this.mainContext.mousePoint(e)));
      
            if(this.mainContext.isHoverPointInCanvas(currentHoverPoint)){
              var selectedClip = this.mainContext.findClickedClip(currentHoverPoint);

              if(selectedClip){
                if (selectedClip.markedToEditPolygon){
                  selectedClip.markedToEditPolygon = false
                } else {
                  let markedClips = cropper.getClips().filter((clip) => clip.markedToEditPolygon);
                  if(markedClips.length == 0 && selectedClip.polygon.length == 4) { // Make a method to check a polygon is a rectangle and use that everythere) . // Only 1 is allowed
                    selectedClip.markedToEditPolygon = true
                  } 
                }
              }
            }
            this.redraw()
          }
        } else {
          removeResult = this.mainContext.removePolygon(currentPoint)
          if (removeResult)
            cropper.setUnsavedChanges()
          cropper.displayLShapedMode = 'edges'
        }
        break
      case 'slice':
        removeResult = this.mainContext.removePath(currentPoint)
        if (removeResult)
          cropper.setUnsavedChanges()
        break
    }
    if (removeResult) {
      cropper.pushState(false, true)
      this.redraw()
      if (this.mainContext.currentPath.length > 0)
        this.outline()
    }
  }

  drawLShapedMode(point = null) {
    if (cropper.lShapePointIndexes.length <= 0 || point) {
      const closestAngle = cropper.polygonInEditMode.map(
        (p) => [this.mainContext.distanceBetweenPoints(point, p), p]
      ).sort(
        (a, b) => a[0] - b[0]
      )[0][1]
      cropper.lShapeAngleIndex = cropper.polygonInEditMode.findIndex((p) => p === closestAngle)
      if (cropper.lShapeAngleIndex === 0) {
        cropper.lShapePointIndexes = [cropper.polygonInEditMode.length - 1, cropper.lShapeAngleIndex, cropper.lShapeAngleIndex + 1]
      } else if (cropper.lShapeAngleIndex === cropper.polygonInEditMode.length - 1) {
        cropper.lShapePointIndexes = [cropper.lShapeAngleIndex - 1, cropper.lShapeAngleIndex, 0]
      } else {
        cropper.lShapePointIndexes = [cropper.lShapeAngleIndex - 1, cropper.lShapeAngleIndex, cropper.lShapeAngleIndex + 1]
      }
    }
    const borderingPoints = cropper.lShapePointIndexes.map((index) => this.mainContext.transformFromOriginalCoordinates(cropper.polygonInEditMode[index]))
    if (cropper.displayLShapedMode === 'corner') {
      this.drawArc(borderingPoints[1])
    } else {
      this.hoverContext.clear()
      this.hoverContext.drawPath({ points: borderingPoints, lineWidth: 1 })
      this.hoverContext.stroke()
    }
  }

  // TODO: for show circle point on polygon
  drawArc(points){
    this.hoverContext.clear()
    this.hoverContext.fillStyle = 'red'
    this.hoverContext.beginPath();
    this.hoverContext.arc(points.x, points.y, 3, 0, 2*Math.PI)
    this.hoverContext.fill()
    this.hoverContext.stroke()
  }

  drawDirection(point, lock = false) {
    const lastPoint = this.mainContext.transformFromOriginalCoordinates(this.mainContext.currentPathLastPoint())
    this.mainContext.lockLine(point, lastPoint, lock)
    this.mainContext.lockToStart(point, true)
    this.hoverContext.clear()
    this.hoverContext.drawPath({ points: [lastPoint, point], lineWidth: 1 })
    this.hoverContext.stroke()
  }

  drawRectangle(point) {
    const startPoint = this.mainContext.transformFromOriginalCoordinates(this.rectangleStartPoint)
    // calculate the rectangle width/height
    const width = point.x - startPoint.x
    const height = point.y - startPoint.y

    this.hoverContext.rect(startPoint.x, startPoint.y, width, height)
    const fillStyle = BuildlqColors.generalClipTypeColor(cropper.entryType);
    this.hoverContext.stroke();
    this.hoverContext.fillStyle = fillStyle;
    this.hoverContext.fill();
  }

  drawCross(point) {
    if (!!point) {
      this.drawStraightLine(point, true, false, false);
      this.drawStraightLine(point, false, false, false);
    }
  }

  drawStraightLine(point, isHorizontal = true, clearContext = true, drawYellow = true) {
    if (clearContext) {
      this.hoverContext.clear()
    }
    const transformedPoint = this.mainContext.transformToOriginalCoordinates(point)
    const scaledWidth = Math.max(this.mainCanvasTarget.width / this.mainContext.globalZoomFactor, this.mainCanvasTarget.width);
    const scaledHeight = Math.max(this.mainCanvasTarget.height / this.mainContext.globalZoomFactor, this.mainCanvasTarget.height);
    const points = [
      {
        x: isHorizontal ? -scaledWidth : transformedPoint.x,
        y: isHorizontal ? transformedPoint.y : -scaledHeight
      },
      {
        x: isHorizontal ? scaledWidth : transformedPoint.x,
        y: isHorizontal ? transformedPoint.y : scaledHeight
      }].map((p) => this.mainContext.transformFromOriginalCoordinates(p))
    this.hoverContext.drawPath({
      points: points,
      lineWidth: 1
    })
    this.hoverContext.stroke()
    if (drawYellow) {
      this.hoverContext.beginPath()
      this.hoverContext.fillStyle = 'rgba(252, 186, 3, 0.5)'
      this.hoverContext.arc(point.x, point.y, 10, 0, Math.PI * 2)
      this.hoverContext.fill()
      this.hoverContext.closePath()
    }
  }

  // show the current potential clipping path
  outline() {
    if (this.mainContext.currentPath.length <= 0)
      return
    this.hoverContext.clear()
    this.mainContext.drawPath({ points: this.mainContext.currentPath, closePath: cropper.slicingMode === 'rectangle' })
    this.mainContext.stroke()
  }

  redraw(extraBorder) {
    this.mainContext.clear()
    this.mainContext.setBackgroundColor()
    this.mainContext.drawImageOnCanvas(this.mainImage, 
      { horizontalOffsetCanvas: this.mainContext.canvasHorizontalMargin, verticalOffsetCanvas: this.mainContext.canvasVerticalMargin, horizontalWidthCanvas: this.mainContext.imageCanvasWidth(), verticalHeightCanvas: this.mainContext.imageCanvasHeight() }
    );
    this.mainContext.initHover(this.hoverContext)

    this.mainContext.initBlur(extraBorder)
    this.mainContext.save()
    this.mainContext.clip()
    this.mainContext.drawImageOnCanvas(this.mainImage, 
      { horizontalOffsetCanvas: this.mainContext.canvasHorizontalMargin, verticalOffsetCanvas: this.mainContext.canvasVerticalMargin, horizontalWidthCanvas: this.mainContext.imageCanvasWidth(), verticalHeightCanvas: this.mainContext.imageCanvasHeight() }
    );
    this.mainContext.restore()
    
    // TODO: Explain these modes
    if (cropper.lShapeMode)
      this.drawLShapedMode()
    if (this.mainContext.gridMode)
      this.mainContext.drawGrid()

    // Drawing slices and polygons
    // TODO: Make a method for it
    switch (cropper.stage) {
      case 'highlight':
      case 'split':
        this.mainContext.drawPolygons({ showEntriesNumbering: cropper.showEntriesNumbering });
        break
      case 'slice':
        this.mainContext.drawSlices()
        break
      default:
        break
    }

    // TODO: Needs improvement and documentation / code  comments
    // Disabling new polygons button if more than maximum are used
    const selector = '[data-action="click->cropper#setSlicingMode"][data-value="polygon"], [data-action="click->cropper#setSlicingMode"][data-value="rectangle"]'
    if (cropper.maxPolygons <= this.mainContext.clips.length){
      // Disabling new polygon tools
      document.querySelectorAll(selector).forEach((button) => {
        button.disabled = true
      })

      if (cropper.selectionMode === 'none'){
        cropper.slicingMode = 'polygon'
        cropper.selectionMode = 'add'
        this.hoverCanvasTarget.style.cursor = 'default'
        document.querySelector('[data-action="click->cropper#setSlicingMode"][data-value="polygon-add"]').click()
      }
    } else {
      // Enabling new polygon tools
      document.querySelectorAll(selector).forEach((button) => {
        button.disabled = false
      })
    }
  }

  resetPath() {
    if (cropper.slicingMode !== 'polygon') {
      return false
    }
    if (this.mainContext.currentPath.length <= 0) {
      return false
    }
    cropper.resetLShapedMode();
    this.mainContext.currentPath = []
    cropper.pushState(false, cropper.unsavedChanges)
    this.redraw()
    return true
  }

  resetRectangle() {
    if (cropper.slicingMode !== 'rectangle')
      return false
    if (!this.rectangleSlicingInPorgress)
      return false

    this.rectangleSlicingInPorgress = false
    this.rectangleStartPoint = { x: 0, y: 0 }
    this.redraw()
    return true
  }

  // this method 'imagePolygon()' is for page only(for split stage) as currently page polygon is not handled from rails side
  imagePolygon(){
    const imageCoordinates  = this.mainContext.getRotatedDimensions(this.mainImage, this.mainContext.rotations[0]);
    const imagePolygon = 
    [
      { x: imageCoordinates.minX, y: imageCoordinates.minY },                       
      { x: imageCoordinates.minX + imageCoordinates.width, y: imageCoordinates.minY },           
      { x: imageCoordinates.minX + imageCoordinates.width, y: imageCoordinates.minY + imageCoordinates.height }, 
      { x: imageCoordinates.minX, y: imageCoordinates.minY + imageCoordinates.height }
    ];
    return imagePolygon;      
  }

  // if a point is clicked outside the main image (on split or slice or highlight stage) then finding the area from path drawn that is inside the main image by subtracting the area outside the main image through intersection functionality
  subtractSelectedPathOutsideMainImage(){
    let imagePolygon;
    if(cropper.isSplitStage()){
      // here we are not using parent polygon because currently page polygons are not handled/available from rails side through data attributes
      imagePolygon = this.imagePolygon();
    } else if(cropper.isSliceStage() || cropper.isHighlightStage()){
      imagePolygon = this.mainContext.parentPolygon;
    }

    let scaledPath = this.mainContext.scaledPath(this.mainContext.currentPath)

    // intersection path
    let intersectionPath = greinerHormann.intersection(imagePolygon, scaledPath)[0]
    if(intersectionPath != null){
      this.mainContext.currentPath = this.mainContext.unscaledPath(intersectionPath);
    }   
  }

  // clip the selected path to a new canvas
  // rewrite is related to state management
  clipIt(rewrite = true) {
    // clip the image into the user's clipping area
    let currentUnsavedChanges = false;
    // Selection mode is basically whether you want to add new clip, merge clip or remove part of clip
    // Todo: Add icons in a file and add link here for better explanation
    
    // feature/1202-while-drawing-clicking-outside-of-image-should-be-possible-split-highlight
    // if a point is clicked outside the main image (on split or slice or highlight stage) then finding the area from path drawn that is inside the main image by subtracting area outside the main image through intersection functionality
    if(this.pointMarkedOutsideMainImage == true){
      this.subtractSelectedPathOutsideMainImage()
    }

    // TODO: Move method findPolygonForAppendOrDiff usages inside here
    if (cropper.selectionMode == 'add' || cropper.isTemporarySlicingModeAppend()) {
    // TODO: remove this code after testing(when clipper-lib is tested and stable)
      if (cropper.polygonInEditMode) {
        const clipper = new clipperLib.Clipper();
        
        // converting polygons to the Clipper format (Array of paths)
        const formatPolygon     = points => points.map(({ x, y }) => ({ X: x, Y: y }));
        const polygonInEditMode = formatPolygon(cropper.polygonInEditMode);
        const currentPath       = formatPolygon(this.mainContext.currentPath);
        
        // add paths to the clipper object to use in union operation
        // prameters explanation: the first parameter is for Path, the second parameter 'seconPolyType' is to differentiate source path and destination path, the third parameter is boolean flag to indicate the path is a closed path that is to connect last point back to the first point
        
        let scale = 100; // Scale factor to preserve precision due to Clipper library limitation
        // Scale up the coordinates
        let scaledPath1 = polygonInEditMode.map(pt => ({ X: Math.round(pt.X * scale), Y: Math.round(pt.Y * scale) }));
        let scaledPath2 = currentPath.map(pt => ({ X: Math.round(pt.X * scale), Y: Math.round(pt.Y * scale) }));

        clipper.AddPath(scaledPath1, clipperLib.PolyType.ptSubject, true);
        clipper.AddPath(scaledPath2, clipperLib.PolyType.ptClip, true);

        // performing union operation
        const pathUnion = [];
        clipper.Execute(clipperLib.ClipType.ctUnion, pathUnion, clipperLib.PolyFillType.pftNonZero, clipperLib.PolyFillType.pftNonZero);
        
        let unscaledUnion = pathUnion.map(path => path.map(pt => ({ X: pt.X / scale, Y: pt.Y / scale })));
        // the union result we get is an array of arrays, converting it to our required format that is single array, and taking points to 3 decimal percision
        const unionedPolygon = unscaledUnion[0].map(({ X, Y }) => ({ x: X, y: Y }));
        // Push the clipped data
        this.mainContext.pushClippedData({
          path: unionedPolygon,
          index: cropper.polygonIndexInEditMode,
          isNewClip: false
        });

        cropper.polygonInEditMode = cropper.polygonIndexInEditMode = false;
        currentUnsavedChanges     = true;
        cropper.resetTemporarySlicingMode();      

      }
    } else if (cropper.selectionMode == 'remove' || cropper.isTemporarySlicingModeDiff()) {
      if (cropper.polygonInEditMode) {
        this.mainContext.pushClippedData({
          path: greinerHormann.diff(cropper.polygonInEditMode, this.mainContext.currentPath)[0],
          index: cropper.polygonIndexInEditMode,
          isNewClip: false
        })
        cropper.polygonInEditMode = cropper.polygonIndexInEditMode = false
        currentUnsavedChanges = true
        cropper.resetTemporarySlicingMode();
      }
    } else {
      if (cropper.isSplitStage()) {
        this.mainContext.pushClippedData({
          image: this.mainContext.image,
          isNewClip: true
        })
        if (cropper.autoSortEntries)
          cropper.sortClips();

      } else if (cropper.isHighlightStage()) {
        this.mainContext.pushClippedData({ entryType: cropper.entryType, addOffset: false, isNewClip: true})
      }
      currentUnsavedChanges = true  
    }

    this.mainContext.clearCurrentPath()
    // Move this state management to cropper
    cropper.pushState(rewrite, currentUnsavedChanges || cropper.unsavedChanges);
    if (currentUnsavedChanges) {
      cropper.setUnsavedChanges()
    }
    // resetting the flag
    this.pointMarkedOutsideMainImage = false;
  }

  // show canvas when mouse on canvas
  showHoverCanvas(){
    this.hoverCanvasTarget.classList.toggle("collapse", false)
  }

  // hide canvas when mouse exit from canvas
  hideHoverCanvas(){
    this.hoverCanvasTarget.classList.toggle("collapse", true)
  }
}
